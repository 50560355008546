body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  width: 100%;
}

p {
  font-family: 'Montserrat', sans-serif;
}

div {
  font-family: 'Montserrat', sans-serif;
}

a {
  font-family: 'Montserrat', sans-serif;
}

span {
  font-family: 'Montserrat', sans-serif;
}


.mont {
  font-family: 'Montserrat', sans-serif;
}

.product-page {

  .banner {
    background-color: 'white';
    padding-top: 0 !important;
  }

  .photo-section {
    background-color: rgb(245, 245, 245);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    min-height: 650px;
  }

  @media screen and (max-width: 992px) {
    .photo-section {
      min-height: 400px;
      height: 400px;
    }
  }

  .summary-section {
    margin: 20px;
    display: flex !important;
    flex-flow: column;
    justify-content: space-between;
    align-items: flex-start;

    .full-height-container {
      .title {
        font-family: 'Montserrat', sans-serif;
        font-size: 28px;
        color: '#47495A';
        margin-top: 10px;
        margin-bottom: 1px;
        font-weight: 500;
      }

      .sub-title {
        font-family: 'Montserrat', sans-serif;
        font-size: 20px;
        color: '#47495A';
        margin-top: 5px;
        margin-bottom: 1px;
        font-weight: 400;
      }

      .content {
        font-family: 'Montserrat', sans-serif;
        font-size: 16px;
        color: '#47495A';
        margin-top: 5px;
        margin-bottom: 15px;
        font-weight: 300;

        li {
          margin: 5px;
        }
      }
    }
  }

  .presentation {
    background-color: #F5F5F5;
    margin-top: 50px;
    padding-top: 30px !important;
    padding-bottom: 50px !important;

    .ui.embed {
      img {
        height: 120px;
        width: 120px;
        margin-left: calc(50% - 60px);
        margin-top: calc(25% - 50px);
      }
    }
  }
}
