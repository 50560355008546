.footer {
  .links-container {
    padding-top: 75px !important;
    padding-bottom: 0;
    background-color: #F5F5F5;
    min-height: 400px;

    > .column {
      p {
        font-size: 14px;
        font-weight: 200;
        color: #323648;

        &.header {
          font-weight: 400;
          margin-bottom: 20px;
        }
      }

      a {
        display: block;
        font-weight: 200;
        margin: 10px 0;
        color: #323648;

        &:hover {
          color: #6C9CCC;
        }
      }

      img {
        height: 100px;
      }
    }
  }

  @media screen and (max-width: 992px) {
    .links-container {
      padding-top: 5px !important;

      > .column {
        text-align: center !important;

        img {
          margin: auto;
          height: 70px;
        }
      }
    }
  }

  .copyright-container {
    padding: 0 !important;
    background-color: white;

    .content {
      display: flex;
      align-items: center;
      padding-left: 20px;
      height: 40px;

      .social-links {
        font-size: 14px;
        font-weight: 200;
        color: #323648;
      }

      @media screen and (max-width: 992px) {
        justify-content: center;
      }
    }
  }
}
