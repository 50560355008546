.page-not-found {
  .banner {
    margin: 40px 10px 200px 10px;

    .header {
      font-size: 24px;
      font-weight: 700;
      text-align: center;
      margin-bottom: 10px;
    }

    .content {
      font-size: 14px;
      text-align: center;
    }

    .links {
      text-align: center;
      list-style-type: none;
      padding: 0;
      margin-top: 20px;

      li {
        margin-bottom: 5px;
      }
    }
  }
}
