@mixin showcase-row {
  height: 450px;

  .column {
    display: flex !important;
    flex-flow: column;
    justify-content: center;

    .content {
      margin: 25px;

      .header {
        font-size: 26px;
        font-weight: 300;
        margin-bottom: 5px;
      }

      .sub-header {
        font-size: 14px;
        font-weight: 300;
      }
    }
  }

  @media only screen and (max-width: 992px) {
    height: 100%;
  }
}


.torocell-for-agriculture-page {
  .showcase {
    background-color: #F5F5F5;

    @media only screen and (max-width: 992px) {
      height: 400px;
    }
  }

  .title {
    height: 400px;
    background-size: cover;
    background-position: 70%;
    background-color: #F5F5F5;

    .header {
      font-size: 32px;
      font-weight: 300;
      margin-bottom: 10px;
      padding-bottom: 5px;
      color: white;
      border-bottom: 1px solid white;
    }
  }

  .introduction {
    @include showcase-row;
    height: 300px;

    .column {
      text-align: center !important;
    }
  }

  .about-torocell {
    @include showcase-row;
  }

  .productivity {
    @include showcase-row;

    .showcase {
      @media only screen and (max-width: 992px) {
        height: 200px;
      }
    }

    .column {
      .content {
        .highlight {
          text-align: center;

          .statistic {
            font-size: 64px;
            font-weight: 500;
            margin-bottom: 0;
          }
        }
      }

      .fine-print {
        position: absolute;
        bottom: 5px;
        right: 18px;
        font-size: 10px;
      }
    }
  }

  .products {
    @include showcase-row;

    .showcase {
      @media only screen and (max-width: 992px) {
        height: 100px;
      }
    }

    .content-with-list {
      justify-content: flex-start;

      .content {
        font-size: 16px;
        font-weight: 300;

        .items {
          list-style-type: none;
          padding: 0;
          margin-top: 20px;

          li {
            margin-bottom: 10px;

            .ui.label {
              font-weight: 500;
              font-style: italic;
            }
          }
        }
      }

      @media only screen and (max-width: 992px) {
        height: 100%;
      }
    }
  }

  .operations {
    @include showcase-row;
    height: 400px;

    .column {
      text-align: center !important;

      .content {

        .header {
          margin-bottom: 25px;
        }

        .sub-header {
          font-size: 16px;
        }
      }
    }
  }

  .talk-to-us {
    height: 450px;
  }
}
