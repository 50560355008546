.ui.sidebar.side-nav {
  width: 300px !important;
  height: 100vh !important;
  overflow-y: scroll !important;
  padding-bottom: 100px;

  &.menu {
    .item {
      text-align: left;
      font-size: 18px;

      .menu {
        .item {
          line-height: 24px;
        }
      }
    }
  }
}
