.navbar {

  .navbar-computer {

    .nav-links {
      font-size: 12px;
    }
  }

  .navbar-mobile {
    padding-top: 0 !important;

    .column {
      padding-left: 5px;
      padding-right: 5px;

      .sidebar-toggle {
        background-color: white;
      }
    }
  }

  .ui.menu {
    box-shadow: none;
    border-radius: 0;
    margin: 0;
    border: none;
    border-bottom: 1px solid #F2F2F2;
    font-size: 16px;
  }
}
