.bio-process-equipment {

  .heading {
    font-size: 24px;
    margin-bottom: 20px;
    font-weight: 700;
    color: #323648;
  }

  .products {
    margin-top: 10px;
    margin-bottom: 40px;

    .product-card {
      .product-image {
        background-repeat: no-repeat;
        background-size: contain;
        height: 300px;
        background-position: center;
        background-color: rgb(245, 245, 245);
      }

      .header {
        color: #323648 !important;
      }
    }
  }

  @media screen and (max-width: 992px) {
    .products {
      margin-left: 20px;
      margin-right: 20px;
    }
  }
}
