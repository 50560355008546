.home {
  .grid {
    .about {
      height: 40vh;
      background-color: white;
      padding-top: 0;

      .column {
        display: flex !important;
        justify-content: center;
        text-align: center !important;
        align-items: center;
        border-bottom: 1px solid #F2F2F2;

        p {
          font-size: 24px;
          color: #47495A;
          font-weight: 200;
        }
      }
    }

    .product-categories {
      min-height: 600px;
      background-color: white;
      padding-top: 70px;

      .category-card {
        height: 450px;
        background-color: white;
        justify-content: center;
        text-align: center;
        align-items: center;
        margin: 0 5px;

        .heading {
          font-size: 22px;
          color: #47495A;
          margin-top: 5px;
          margin-bottom: 1px;
          font-weight: 300;
        }

        .image {
          height: 200px;
          background-color: rgb(235,235,235);
          background-position: 50% 20%;
          background-size: cover;
          background-repeat: no-repeat;
          margin-top: 30px;
        }

        .description-container {
          padding: 5px 5px;
          margin-bottom: 5px;

          .description {
            font-size: 17px;
            color: #47495A;
            font-weight: 200;
          }
        }
      }
    }


    .client-section {
      height: 525px;
      background-color: #F5F5F5;
      padding-top: 10px;

      .column {
        text-align: center !important;
      }

      .header {
        font-size: 32px;
        color: #323648;
        margin-top: 10px;
        margin-bottom: 15px;
        font-weight: 300;
      }

      .content {
        font-size: 16px;
        color: #323648;
        font-weight: 300;
        margin-bottom: 0;
      }

      .client-container {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        opacity: 0.8;
        margin-top: 20px;

        .clients {
          text-align: center;

          .client-logo {
            width: 120px;
            height: 60px;
            margin: 15px 0;
          }
        }
      }
    }
  }
}
