.contact-page {
  .email-us {
    min-height: 200px;

    .column {
      display: flex !important;
      flex-flow: row;
      justify-content: center;
      align-items: center;
      text-align: center !important;

      p {
        font-size: 36px;
        font-weight: 300;
      }

      button {
        margin-bottom: 20px;
      }
    }
  }

  .information {
    background-color: #F5F5F5;
    min-height: 300px;

    .column.section {
      display: flex !important;
      flex-flow: row;
      justify-content: flex-start;
      padding-top: 50px;

      .heading {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 5px;
      }

      .content {
        font-size: 18px;
        margin-bottom: 5px;
      }
    }

    @media screen and (max-width: 992px) {
      .column.section {
        padding-top: 10px;
        margin-left: 30px;
        padding-bottom: 15px;

        .heading {
          font-size: 20px;
          font-weight: 500;
          margin-bottom: 5px;
        }

        .content {
          font-size: 18px;
          margin-bottom: 5px;
        }
      }
    }
  }

  .send-message {
    margin: 50px 15px;

    p {
      font-size: 20px;
    }
  }
}
