.contact-bar {
  .container {
    height: 30vh;
    background-color: white;
    padding-top: 0;

    > .content {
      display: flex !important;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: center;

      > .message {
        font-family: 'Montserrat', sans-serif;
        font-size: 28px;
        color: #323648;
        font-weight: 200;
      }
    }

    @media screen and (max-width: 992px) {
      > .content {
        justify-content: center;
      }
    }
  }
}
