.page-with-sidebar {
  .pushable {
    &.segment {
      border: none;
      border-radius: 0;
    }
  }

  .pusher {
    @media screen and (min-width: 992px) {
      .segment {
        padding-top: 0;
      }
    }
    @media screen and (max-width: 992px) {
      .segment {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}
