.research-banner {
  .grid {
    .row {
      height: 50vh;
      background-color: #FCFDFD;
      padding: 0 !important;
      margin-left: 14px;
      background-position: bottom;
      background-size: cover;

      .column {
        height: 100%;
        display: flex !important;
        flex-flow: row nowrap;
        justify-content: flex-end;
        align-items: center;

        .banner-container {
          border-radius: 4px;
          background-color: rgba(0,0,0,0.3);
          padding: 10px 10px;
          width: 40%;
          margin-right: 14px;

          .heading {
            font-size: 4vh;
            font-weight: 300;
            color: #FAFAFA;
            margin-bottom: 2px;
          }

          .content {
            font-size: 2vh;
            font-weight: 200;
            color: #FAFAFA;
          }
        }
      }

      @media only screen and (max-width: 992px) {
        .column {
          justify-content: center;
          align-items: center;

          .banner-container {
            width: 80%;
          }
        }
      }
    }
  }
}
