.about-page {
  .banner {
    margin: 40px 10px;

    .header {
      font-size: 24px;
      font-weight: 700;
      text-align: center;
    }

    .content {
      font-size: 18px;
      text-align: center;
    }
  }

  .team {
    height: 200px;
  }
}
