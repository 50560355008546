.request-quote-page {
  .request-form {
    margin: 40px 10px;

    .header {
      font-size: 24px;
      font-weight: 700;
    }
  }
}
